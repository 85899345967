import Header from "../components/header";
import { Box, CircularProgress, Container, useTheme } from "@mui/material";
import Chat from "../components/chat";
import ChatInput from "../components/chatInput";
import { useEffect, useRef, useState } from "react";
import useTenantStore from "../store/themeStore";
import { getHistory, resetSession } from "../services/historyService";
import { Disclaimer } from "../components/disclaimer";

export interface ChatMessage {
    content: string
    messageType: MessageType
    completed: boolean
    error: boolean
    errorType: string | null
}

export enum MessageType {
    USER = "USER",
    ASSISTANT = "ASSISTANT"
}

export default function ChatPage() {
    const { tenant } = useTenantStore();
    const [disclaimerOpen, setDisclaimerOpen] = useState<boolean>(false);
    const [history, setHistory] = useState<ChatMessage[]>([])
    const [input, setInput] = useState<string>("")
    const [isGenerating, setIsGenerating] = useState<boolean>(false);

    const chatContainerRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();

    useEffect(() => {
        loadHistory();
        const disclaimer = localStorage.getItem("disclaimer");
        if (!disclaimer) {
            setDisclaimerOpen(true);
        }
    }, [])

    function scrollToBottom() {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    function loadHistory() {
        getHistory()
            .then(res => {
                const entries = res.messages;
                const updatedEntries = entries.map((e: ChatMessage) => ({
                    ...e, messageType: e.messageType as MessageType
                }));
                setHistory(updatedEntries);
                scrollToBottom();
            });
    }

    function appendToHistory(history: ChatMessage[], content: string, finishReason: string) {
        const lastIndex = history.length - 1;
        const lastMessage = history[lastIndex];
        let finished: boolean = false;
        if (finishReason !== null && finishReason !== undefined) {
            finished = finishReason.toUpperCase() === "STOP";
        }
        return [...history.slice(0, lastIndex), {
            ...lastMessage,
            content: lastMessage.content + content,
            completed: finished,
        }]
    }

    function setMessageError(history: ChatMessage[], errorType: string | null) {
        const lastIndex = history.length - 1;
        const lastMessage = history[lastIndex];

        return [...history.slice(0, lastIndex), {
            ...lastMessage,
            error: true,
            errorType: errorType,
            completed: true
        }];
    }

    function performRequest() {
        // Cancel requests if one response hasn't finished yet
        if (isGenerating || input.length === 0) {
            return;
        }
        sendMessage(input);
    }

    function newChat() {
        resetSession().then(() => {
            setHistory([]);
        })
    }

    function sendMessage(text: string) {
        setHistory([...history, {
            messageType: MessageType.USER,
            content: text,
            completed: true,
            error: false,
            errorType: null
        }, {
            messageType: MessageType.ASSISTANT,
            content: "",
            completed: false,
            error: false,
            errorType: null
        }])
        setInput("");
        setIsGenerating(true);
        scrollToBottom();

        const eventSource = new EventSource(`${process.env.REACT_APP_BASE_URI}/api/chat/rag?message=${encodeURIComponent(text)}&t=${tenant?.publicId}`, { withCredentials: true });


        eventSource.onmessage = (event) => {
            let response = JSON.parse(event.data);
            let message = response.generation;
            let errorType = response.errorType;
            if (errorType != null) {
                setHistory(currentHistory => setMessageError(currentHistory, errorType));
                setIsGenerating(false);
                scrollToBottom();
                return;
            }
            let content = message?.output?.content || "";
            setHistory(currentHistory => appendToHistory(currentHistory, content, message.metadata.finishReason));
            scrollToBottom();
        };

        eventSource.onerror = (event) => {
            if (event.eventPhase == 2) {
                setIsGenerating(false);
                eventSource.close();
                scrollToBottom();
                // EventPhase 2 == Connection closed.
                // This shall be okay and is always send after the completion of a message
                return;
            }
            console.error('EventSource failed:', event);
            setIsGenerating(false);
            eventSource.close();
            setHistory(currentHistory => setMessageError(currentHistory, null));
            scrollToBottom();
        };

        return () => {
            console.log("Closing");
            setIsGenerating(false);
            eventSource.close();
        };
    }


    if (!tenant) {
        return <Box sx={{ width: '100%', height: '100vh', textAlign: 'center', alignContent: 'center' }}>
            <Header />
            <CircularProgress />
        </Box>
    }

    if (disclaimerOpen) {
        return <Disclaimer setOpen={(value) => setDisclaimerOpen(value)} />
    }

    return <Box sx={{ height: '100%' }}>
        <Header />
        <Container sx={{ position: 'relative', pb: { xs: 18, md: 16 } }}>
            <Chat history={history} bottomRef={chatContainerRef} sendMessage={sendMessage} tenant={tenant} />
        </Container>
        <Box sx={{ position: 'fixed', backgroundColor: theme.palette.background.default, bottom: 0, right: 0, left: 0 }}>
            <Container>
                <Box sx={{ my: 2 }}>
                    <ChatInput input={input} setInput={setInput} performRequest={performRequest}
                        newChat={newChat}
                        newChatDisabled={isGenerating || history.length === 0}
                        sendingDisabled={input.length === 0 || isGenerating}
                        openPrivacy={() => setDisclaimerOpen(true)
                        }
                    />
                </Box>
            </Container>
        </Box>
    </Box>

}
