const loginURL = `${process.env.REACT_APP_BASE_URI}/api/login`;

export async function login(username :string, password: string): Promise<boolean> {
    const request = await fetch(loginURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            'username': username,
            "password": password,
        }),
        credentials: 'include'
    });
    return request.ok;
}
