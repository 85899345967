import React from "react";
import { Box, Button, Fade, Grid, Typography } from "@mui/material";
import ChatMessageBox from "./chatMessageBox";
import { ChatMessage } from "../pages/ChatPage";
import { useTranslation } from "react-i18next";
import { Tenant } from "../models/tenant";

export interface ChatProps {
    history: ChatMessage[],
    bottomRef: React.RefObject<HTMLDivElement>,
    sendMessage: (text: string) => void,
    tenant: Tenant
}

export default function Chat({ history, bottomRef, sendMessage, tenant }: ChatProps) {
    return (
        <Box sx={{ padding: 2 }}>
            {history.length === 0
                ? <EmptyState sendMessage={sendMessage} tenant={tenant} />
                : history.map((entry, index) =>
                    <Fade key={index} in={true}>
                        <Box>
                            <ChatMessageBox message={entry} userLogo={tenant.theme.logo} />
                        </Box></Fade>
                )}
            <div ref={bottomRef} />
        </Box>
    )
}

interface EmptyHistoryProps {
    sendMessage: (text: string) => void,
    tenant: Tenant
}

function EmptyState({ sendMessage, tenant }: EmptyHistoryProps) {
    const { t } = useTranslation();

    return <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant='h4'>{t('chat.empty.headline')}</Typography>
        <Typography variant='h5'>{t('chat.empty.subtitle')}</Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={4}>
                <Button variant='outlined' fullWidth sx={{ textTransform: 'none', height: '100%' }} onClick={() => sendMessage(tenant.exampleQuestion1)}>
                    {tenant.exampleQuestion1}
                </Button>
            </Grid>
            <Grid item xs={12} md={4}>
                <Button variant='outlined' fullWidth sx={{ textTransform: 'none', height: '100%' }} onClick={() => sendMessage(tenant.exampleQuestion2)}>
                    {tenant.exampleQuestion2}
                </Button>
            </Grid>
            <Grid item xs={12} md={4}>
                <Button variant='outlined' fullWidth sx={{ textTransform: 'none', height: '100%' }} onClick={() => sendMessage(tenant.exampleQuestion3)}>
                    {tenant.exampleQuestion3}
                </Button>
            </Grid>
        </Grid>
    </Box >
}
