const tenantBaseURI = `${process.env.REACT_APP_BASE_URI}/api/conversations`;

const request = async (path: string) => {
    const options = {
        method: "GET",
        credentials: 'include' as RequestCredentials,
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const response = await fetch(`${tenantBaseURI}/${path}`, options);
    if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
    }
    return response;
}

export const getHistory = () => request("").then(res => res.json());
export const resetSession = () => request("reset");
