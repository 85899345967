import { createTheme, ThemeProvider } from "@mui/material";
import { ReactNode, useMemo } from "react";
import useTenantStore from "../store/themeStore";

interface ThemeWrapperProps {
    children: ReactNode;
}

export default function ThemeWrapper({ children }: ThemeWrapperProps) {
    const { tenant } = useTenantStore();
    const customTheme = useMemo(() =>
        createTheme({
            palette: {
                primary: {
                    main: tenant.theme.primaryColor
                },
                secondary: {
                    main: tenant.theme.secondaryColor
                }
            }
        }), [tenant]);

    return <ThemeProvider theme={customTheme}>
        {children}
    </ThemeProvider>
}
