import {
    Backdrop,
    Button,
    Chip,
    CircularProgress,
    Container, Dialog, FormControl,
    Grid,
    IconButton, InputLabel,
    MenuItem,
    Select,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow, Typography
} from "@mui/material";
import Header from "../components/header";
import { ChangeEvent, useEffect, useState } from "react";
import { deleteDocument, listDocuments, previewDocument, uploadDocument } from "../services/documentService";
import { Page, VectorDocument } from "../models/document";
import { CloudUpload, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Tenant } from "../models/tenant";
import { getTenants } from "../services/tenantService";

export default function DocumentsPage() {
    const [documents, setDocuments] = useState<Page<VectorDocument> | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(0)

    const [previewId, setPreviewId] = useState<number | null>(null);
    const [previewData, setPreviewData] = useState<string | undefined>(undefined);

    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [selectedTenant, setSelectedTenant] = useState<string>("ti&m");

    const [isUploading, setUploading] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        getTenants().then(res => setTenants(res));
    }, []);

    useEffect(() => {
        loadDocuments()
    }, [currentPage, selectedTenant]);

    function loadDocuments() {
        const publicTenantId = selectedTenant !== "ti&m" ? selectedTenant : "";
        listDocuments(currentPage, publicTenantId).then(res => {
            setPreviewId(null);
            setPreviewData(undefined);
            setDocuments(res);
        }).catch(() => {
            window.location.href = "/admin"
        });
    }

    function preview(id: number) {
        previewDocument(id).then(res => setPreviewData(URL.createObjectURL(res)));
        setPreviewId(id)
    }

    function uploadFile(event: ChangeEvent<HTMLInputElement>) {
        if (!event.target.files) {
            alert("Please select at least one file!");
            return;
        }
        setUploading(true);
        const publicTenantId = selectedTenant !== "ti&m" ? selectedTenant : "";
        uploadDocument(publicTenantId, event.target.files).then(() => {
            loadDocuments();
            setUploading(false);
        });
    }

    function deleteFile(id: number) {
        deleteDocument(id).then(() => {
            loadDocuments();
        })
    }

    return <>
        <Header />
        <Container maxWidth="xl">
            <Grid container spacing={4} sx={{ mt: 4 }}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        {t('document.title')}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="label">{t('document.selectedTenant')}</InputLabel>
                        <Select
                            fullWidth
                            labelId="label"
                            value={selectedTenant}
                            onChange={(e) => setSelectedTenant(e.target.value)}
                        >
                            <MenuItem value="ti&m">{t('document.defaultTenant')}</MenuItem>
                            {tenants.map(t => <MenuItem key={t.publicId} value={t.publicId}>{t.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sx={{ mt: 1 }}>
                    <Button
                        size='large'
                        fullWidth
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUpload />}
                        sx={{ mb: 2 }}
                    >
                        {t('document.uploadFile')}
                        <VisuallyHiddenInput type="file" onChange={uploadFile} multiple />
                    </Button>
                </Grid>
                <Grid item xs={8}>
                    <Overview page={documents} selectedId={previewId} previewCallback={preview} deleteFile={deleteFile} changePage={(n) => setCurrentPage(n)} />
                </Grid>
                <Grid item xs={4} sx={{ minHeight: "50vh" }}>
                    <iframe src={previewData} height="100%" width="100%" />
                </Grid>
            </Grid>
        </Container>
        <Backdrop open={isUploading}>
            <CircularProgress />
        </Backdrop>
    </>
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface OverviewProps {
    page: Page<VectorDocument> | null;
    selectedId: number | null;
    previewCallback: (documentId: number) => void;
    deleteFile: (documentId: number) => void;
    changePage: (number: number) => void;
}

export function Overview({ page, selectedId, previewCallback, deleteFile, changePage }: OverviewProps) {
    const { t } = useTranslation();
    if (page == null) {
        return <CircularProgress />
    }
    if (page.empty) {
        return <>{t('document.empty')}</>
    }
    return <>
        <TableContainer sx={{ width: "100%" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('document.fileName')}</TableCell>
                        <TableCell>{t('document.tenant')}</TableCell>
                        <TableCell>{t('document.status')}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody sx={{ cursor: 'pointer' }}>
                    {page.content.map(doc => <TableRow
                        key={doc.id}
                        hover
                        selected={doc.id === selectedId}
                        onClick={() => previewCallback(doc.id)}
                    >
                        <TableCell>
                            {doc.fileName}
                        </TableCell>
                        <TableCell>
                            {doc.tenantName || "ti&m"}
                        </TableCell>
                        <TableCell>
                            <Chip label={t('document.status' + doc.status)} color={doc.status === 'PROCESSED' ? 'success' : doc.status === 'UPLOADED' ? 'warning' : 'error'} />
                        </TableCell>
                        <TableCell>
                            <IconButton onClick={() => deleteFile(doc.id)}>
                                <Delete />
                            </IconButton>
                        </TableCell>

                    </TableRow>)
                    }
                </TableBody>
                <TableFooter>
                    <TablePagination
                        count={page.totalPages}
                        page={page.number}
                        rowsPerPage={page.size}
                        onPageChange={(e, p) => changePage(p)}
                        rowsPerPageOptions={[10]}
                    />
                </TableFooter>
            </Table>
        </TableContainer>
    </>
}
