import { create } from 'zustand';
import { defaultTenant, Tenant } from '../models/tenant';

interface TenantStore {
    tenant: Tenant;
    setTenant: (tenant: Tenant) => void
}

const useTenantStore = create<TenantStore>()((set) => ({
    tenant: defaultTenant,
    setTenant: (tenant) => set({ tenant: tenant })
}));

export default useTenantStore;
