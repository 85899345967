import { AppBar, Box, Button, Dialog, DialogContent, Hidden, Icon, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import logo from "../assets/logo_white.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Assistant, Check, ChevronLeft } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function Header() {
    const theme = useTheme();
    const { t } = useTranslation();
    const primaryColor = theme.palette.primary.main;
    const secondaryColor = theme.palette.secondary.main
    const location = useLocation();
    const navigate = useNavigate();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    return <>
        <AppBar position="fixed">
            <Toolbar sx={{ background: `linear-gradient(90deg, ${primaryColor}, ${secondaryColor})`, py: 2 }}>
                <IconButton edge="start" color="inherit" sx={{ mr: 2 }} onClick={() => navigate(-1)} disabled={location.pathname === '/'}>
                    {location.pathname === "/"
                        ? <Icon />
                        : <ChevronLeft />
                    }
                </IconButton>
                <Link to="/">
                    <img src={logo} alt='TI&M Logo' height={60} />
                </Link>
                <Banner />
                <Hidden smDown>
                    <Box sx={{ ml: 'auto', mr: { sm: '4rem', md: '10rem' } }}>
                        <Link to='https://www.ti8m.com/de/services/artificial-intelligence/your-gpt' target='_blank'>
                            <Button startIcon={<Assistant />} variant="contained" sx={{ textTransform: 'none' }}>{t(mdUp ? 'chat.empty.about.full' : 'chat.empty.about.small')}</Button>
                        </Link>
                    </Box>
                </Hidden>
            </Toolbar>
        </AppBar>
        {/*needed due to fixed placement https://mui.com/material-ui/react-app-bar/#fixed-placement*/}
        <Toolbar sx={{ mb: 4 }} />
    </>
}

function Banner() {
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    return <>
        <Button
            sx={{
                position: 'fixed',
                top: '10px',
                right: '-60px',
                width: '200px',
                padding: '6px',
                backgroundColor: 'rgba(255, 0, 0, 0.7)',
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center',
                transform: 'rotate(30deg)',
                zIndex: 1000,
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: 'rgba(255, 0, 0, 0.9)',
                }
            }}
            onClick={() => setOpen(true)}
        >
            Demo
        </Button>
        <Dialog open={open}>
            <DialogContent>
                <Typography variant="h4">{t('demo.title')}</Typography>
                <Typography>{t('demo.description')}</Typography>
                <Button sx={{ mt: 1 }} fullWidth variant="outlined" color="success" onClick={() => setOpen(false)}>
                    <Check sx={{ mr: 1 }} />{t('demo.accept')}
                </Button>
            </DialogContent>
        </Dialog>
    </>
}

export default Header;
