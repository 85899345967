import { Launch } from "@mui/icons-material";
import { Button, Container, Divider, Typography } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import Header from "./header";

type DisclaimerProps = {
    setOpen: (value: boolean) => void
}

export const Disclaimer = ({ setOpen }: DisclaimerProps) => {
    const { t } = useTranslation();

    function acceptUsage() {
        localStorage.setItem("disclaimer", "true");
        setOpen(false);
    }

    return <>
        <Header />
        <Container sx={{ pt: 2, mb: 8 }}>
            <Typography variant="h3">{t('disclaimer.title')}</Typography>
            <Typography variant="h5">{t('disclaimer.subtitle')}</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant='h4'>{t('disclaimer.functions.title')}</Typography>
            <Trans t={t} i18nKey='disclaimer.functions.description' />
            <Divider sx={{ my: 2 }} />
            <Typography variant='h4'>{t('disclaimer.privacy.title')}</Typography>
            <Trans t={t} i18nKey='disclaimer.privacy.description' />
            <Button href="https://www.ti8m.com/de/datenschutz" variant="text" sx={{ textTransform: 'none' }} target='_blank'><Launch sx={{ mr: 1 }} />{t('disclaimer.privacy.externalRef')}</Button>
            <Divider sx={{ my: 2 }} />
            <Typography>{t('disclaimer.privacy.confirmation.title')}</Typography>
            <Button onClick={acceptUsage} fullWidth variant="outlined" sx={{ textTransform: 'none', mt: 2 }}>{t('disclaimer.privacy.confirmation.button')}</Button>
        </Container>
    </>
}
