const baseURI = process.env.REACT_APP_BASE_URI;

export const fetchReadiness = async () => {
    // Set minimum delay to have a smooth loading feeling.
    await new Promise(resolve => setTimeout(resolve, 250));
    return fetch(`${baseURI}/actuator/health`, {
        credentials: 'include' as RequestCredentials,
        mode: "no-cors"
    })
}

const loadingScreenMessages = [
    "Optimiere künstliche Intelligenz... Bitte warten Sie geduldig.",
    "Lade Zukunftstechnologien... Ihre Geduld ist futuristisch!",
    "Kalibriere Algorithmen... Gleich geht’s los!",
    "Implementiere innovative Lösungen... Fast fertig!",
    "Synchronisiere mit der Cloud... Fast wie Schweben!",
    "Aktualisiere Software-Protokolle... Ein Moment der Innovation.",
    "Berechne Optimierungspfade... Die Effizienz ist greifbar nah!",
    "Entwickle benutzerdefinierte Schnittstellen... Gleich tauchen wir ein.",
    "Integriere Systemplattformen... Zusammenarbeit in Aktion!",
    "Kodiere zukunftsweisende Projekte... Die Zukunft beginnt jetzt.",
    "Verfeinere User Experience... Fast zu 100% benutzerfreundlich.",
    "Verstärke Sicherheitsprotokolle... Sicherheit geht vor!",
    "Erweitere digitale Horizonte... Die Grenzen verschwimmen.",
    "Pflege Datenbanken... Ein Datenmeer wird durchsegelt.",
    "Teste innovative Frameworks... Der Fortschritt wartet nicht."
]

export function getMessage() {
    return loadingScreenMessages[Math.floor(Math.random() * loadingScreenMessages.length)]
}
