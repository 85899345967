import { Business, Description, CloudUpload, SmartToy, AlternateEmail, Badge } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, styled, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { useTranslation } from "react-i18next";
import { Tenant } from "../models/tenant";

export interface CreateModalProps {
    tenant: Tenant,
    setTenant: React.Dispatch<React.SetStateAction<Tenant>>,
    open: boolean,
    onClose: () => void,
    addCompany: () => void,
    deleteCompany: (id: number | undefined) => void
}

export function CreateModal({
    tenant: tenant,
    setTenant: setTenant,
    open,
    onClose,
    addCompany,
    deleteCompany
}: CreateModalProps) {
    const { t } = useTranslation();

    function changeField(field: string, value: string) {
        setTenant((prevState: any) => ({ ...prevState, [field]: value }));
    };

    function changeTheme(field: string, value: string) {
        setTenant((prevState: any) => ({ ...prevState, theme: { ...prevState.theme, [field]: value } }));
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    let title = t('tenant.edit')
    // new tenant 
    if (tenant.id === undefined) {
        title = t('tenant.create')
    }

    function uploadImage(file: File | null | undefined) {
        if (file === null || file === undefined) {
            console.error("Can't upload empty file");
            return;
        }
        const reader = new FileReader();
        reader.onload = function(event) {
            changeTheme('logo', event.target?.result as string);
        }
        reader.readAsDataURL(file);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
            fullScreen={isMobile}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={9}>
                        <TextField autoFocus name="companyName" label={t('tenant.companyName')} fullWidth value={tenant.name} onChange={(e) => changeField('name', e.target.value)} InputProps={{ startAdornment: <InputAdornment position="start"><Business /></InputAdornment> }} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button component="label" variant="contained" sx={{ height: '100%' }} fullWidth startIcon={<CloudUpload />}>
                            {t('tenant.logo')}
                            <VisuallyHiddenInput type="file" onChange={(e) => uploadImage(e.target.files?.item(0))} />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="description" label={t('tenant.description')} fullWidth value={tenant.description} onChange={(e) => changeField('description', e.target.value)} InputProps={{ startAdornment: <InputAdornment position="start"><Description /></InputAdornment> }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiColorInput name="primaryColor" label={t('tenant.primaryColor')} fullWidth value={tenant.theme.primaryColor} onChange={(v) => changeTheme('primaryColor', v)} />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiColorInput name="secondaryColor" label={t('tenant.secondaryColor')} fullWidth value={tenant.theme.secondaryColor} onChange={(v) => changeTheme('secondaryColor', v)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name="contactName" label={t('tenant.contactName')} fullWidth value={tenant.salesContactPerson} onChange={(e) => changeField('salesContactPerson', e.target.value)} InputProps={{ startAdornment: <InputAdornment position="start"><Badge /></InputAdornment> }} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name="contactMail" label={t('tenant.contactMail')} fullWidth value={tenant.salesContactEMail} onChange={(e) => changeField('salesContactEMail', e.target.value)} InputProps={{ startAdornment: <InputAdornment position="start"><AlternateEmail /></InputAdornment> }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="aiContextInformation" label={t('tenant.aiContextInformation')} fullWidth value={tenant.aiContextInformation} onChange={(e) => changeField('aiContextInformation', e.target.value)} InputProps={{ startAdornment: <InputAdornment position="start"><SmartToy /></InputAdornment> }} multiline rows={4} />
                    </Grid>

                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12}>
                            <Typography>{t('tenant.examples')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined" onChange={(e) => changeField('exampleQuestion1', e.target.value)} value={tenant.exampleQuestion1} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined" onChange={(e) => changeField('exampleQuestion2', e.target.value)} value={tenant.exampleQuestion2} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined" onChange={(e) => changeField('exampleQuestion3', e.target.value)} value={tenant.exampleQuestion3} />
                        </Grid>

                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                {!isMobile &&
                    <Button onClick={() => deleteCompany(tenant.id)} disabled={tenant.id === undefined} variant="contained" color="error" sx={{ mr: 'auto' }}>{t('tenant.actions.delete')}</Button>
                }
                <Button onClick={onClose} color="error" variant="outlined">{t('tenant.actions.cancel')}</Button>
                <Button onClick={addCompany} color="success" variant="contained">{t('tenant.actions.create')}</Button>
            </DialogActions>
        </Dialog >
    )
}

export default CreateModal;
