import { Tenant } from "../models/tenant";

const tenantBaseURI = `${process.env.REACT_APP_BASE_URI}/api/tenants`;

const request = async (endpoint: string, method: string = 'GET', body?: any) => {
    const options = {
        method,
        credentials: 'include' as RequestCredentials,
        headers: {
            'Content-Type': 'application/json',
        },
        body: body ? JSON.stringify(body) : undefined
    };
    const response = await fetch(`${tenantBaseURI}/${endpoint}`, options);

    if (!response.ok) {
        if(response.status === 401){
            window.location.href = '/login';
            return;
        }
        throw new Error(`HTTP error ${response.status}`);
    }
    return method === 'DELETE' ? response.ok : response.json();
}

export const getTenantByPublicId = (publicId: string) => request(`public/${publicId}`);
export const getTenants = () => request('', 'GET');
export const createTenant = (tenant: Tenant) => request('', 'POST', tenant);
export const updateTenant = (tenant: Tenant) => request(tenant.id.toString(), 'PUT', tenant);
export const deleteTenant = (id: number) => request(id.toString(), 'DELETE');
