import './App.css';
import ChatPage from './pages/ChatPage';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminPage from "./pages/AdminPage";
import ThemeWrapper from "./components/themeWrapper";
import { Box, CssBaseline, Fade } from '@mui/material';
import LoadingPage from './pages/LoadingPage';
import { useEffect, useState } from 'react';
import { fetchReadiness } from './services/startupService';

import PageNotFound from './pages/PageNotFound';
import { getTenantByPublicId } from './services/tenantService';
import useTenantStore from './store/themeStore';
import DocumentsPage from "./pages/DocumentsPage";
import { LoginPage } from "./pages/LoginPage";

function App() {
    const [readiness, setReadiness] = useState<boolean>(false);
    const { setTenant } = useTenantStore();

    useEffect(() => {
        fetchReadiness().then(() => setReadiness(true))
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        let publicId = params.get('t');
        if (publicId == null && sessionStorage.getItem("tenant") !== null) {
            publicId = sessionStorage.getItem("tenant");
        }
        if (publicId != null) {
            sessionStorage.setItem("tenant", publicId)
            getTenantByPublicId(publicId)
                .then(tenant => {
                    setTenant(tenant);
                })
                .catch(err => console.error(err)) // TODO: Show error to user
        }
    }, [])

    if (window.location.pathname === "/" && !readiness) {
        return <LoadingPage />
    }

    return <Fade in={true}>
        <Box>
            <ThemeWrapper>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<ChatPage />}></Route>
                        <Route path="login" element={<LoginPage />}></Route>
                        <Route path="admin" element={<AdminPage />}></Route>
                        <Route path="document" element={<DocumentsPage />}></Route>
                        <Route path="*" element={<PageNotFound />}></Route>
                    </Routes>
                </BrowserRouter>
            </ThemeWrapper>
        </Box>
    </Fade>
}

export default App;
