import React, { useState } from "react";
import Header from "../components/header";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { login } from "../services/loginService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();
    const {t} = useTranslation();

    function loginUser() {
        login(username, password).then(success => {
                if (success) {
                    navigate('/admin')
                } else {
                    console.error('cant login user');
                }
            }
        )
    }

    return <>
        <Header/>
        <Container maxWidth="sm">
            <Box sx={{ pt: 8 }}>
                <Typography variant="h4">{t('admin.login')}</Typography>
                <TextField value={username} onChange={(e) => setUsername(e.target.value)} fullWidth
                           label={t('admin.username')} sx={{ mt: 1 }}/>
                <TextField value={password} onChange={(e) => setPassword(e.target.value)} fullWidth type="password"
                           label={t('admin.password')} sx={{ mt: 1 }}
                           onKeyDown={(event) => {
                               if (event.key === 'Enter') {
                                   loginUser()
                               }
                           }}
                />
                <Button variant="contained" sx={{ mt: 1, ml: 'auto', display: 'flex' }}
                        onClick={loginUser}>{t('admin.login')}</Button>
            </Box>
        </Container>
    </>
}
