import {Box, Link} from "@mui/material";
import Header from "../components/header";
import pageNotFoundImage from "../assets/pageNotFoundImage.jpg";
import React from "react";
import {useTranslation} from "react-i18next";


export default function PageNotFound(){

    const {t} = useTranslation();


    return <Box sx={{height: '100%'}}>
        <Header/>
        <div className="page-not-found-container">
            <h1>{t('pages.notFound')}</h1>
            <Link href="/"><img src={pageNotFoundImage} alt='PageNotFoundImage' className="page-not-found-img"/></Link>
        </div>
    </Box>
}
