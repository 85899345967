import { Box, Button, IconButton, TextField, Tooltip } from "@mui/material";
import { MapsUgcOutlined, Send } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";

export interface ChatInputProps {
    input: string
    setInput: Dispatch<SetStateAction<string>>,
    performRequest: () => void,
    newChat: () => void,
    newChatDisabled: boolean,
    sendingDisabled: boolean,
    openPrivacy: () => void,
}

export default function ChatInput({ input, setInput, performRequest, newChat, newChatDisabled, sendingDisabled, openPrivacy }: ChatInputProps) {
    const { t } = useTranslation();
    return <>
        <Box display="flex">
            <Tooltip title={t('chat.reset')} placement='top'>
                <IconButton size="large" sx={{ border: '1px #00000054 solid', borderRadius: 1, height: '56px', width: '56px', mr: 1 }} onClick={newChat} disabled={newChatDisabled}>
                    <MapsUgcOutlined />
                </IconButton>
            </Tooltip>
            <TextField
                label={t('chat.inputPlaceholder')}
                variant="outlined"
                fullWidth
                value={input}
                autoFocus
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        performRequest()
                    }
                }}
                InputProps={{
                    endAdornment: <Tooltip title={t('chat.submit')} placement='top'>
                        <IconButton
                            disabled={sendingDisabled}
                            onClick={() => performRequest()}>
                            <Send color={sendingDisabled ? 'disabled' : 'primary'} />
                        </IconButton>
                    </Tooltip>
                }}
            />
        </Box>
        <Button onClick={openPrivacy} sx={{ textTransform: 'none' }} size="small" variant="text">{t('chat.privacy')}</Button>
    </>
}

