const tenantBaseURI = `${process.env.REACT_APP_BASE_URI}/api/document`;

const request = async (path: string, method: string = "GET", contentType: string = "application/json", body: any = null) => {
    const options = {
        method: method,
        credentials: 'include' as RequestCredentials,

        body: body
    };
    const response = await fetch(`${tenantBaseURI}${path}`, options);
    if (!response.ok) {
        if(response.status === 401){
            window.location.href = '/login';
            return;
        }
        throw new Error(`HTTP error ${response.status}`);
    }
    return response;
}

export const listDocuments = (page: number, publicTenantId: string) => request(`?size=10&page=${page}&t=${publicTenantId}`).then(res => res!.json());
export const previewDocument = (id: number) => request(`/${id}/preview`).then(res => res!.blob());
export const uploadDocument = async (publicTenantId: string, files: FileList) => {
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
        data.append("files", files[i]);
    }
    const res = await request(`?t=${publicTenantId}`, "POST", "", data);
    return await res!.json();
};
export const deleteDocument = (id: number) => request(`/${id}`, "DELETE");
