import { Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getMessage } from "../services/startupService";
import logo from "../assets/logo.png";

function LoadingScreen() {

    const [message, setMessage] = useState(getMessage());
    useEffect(() => {
        const interval = setInterval(() => {
            setMessage(getMessage)
        }, 2000)
        return () => clearInterval(interval);
    }, [])

    return <>
        <Grid
            container
            spacing={3}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={12}>
                <img src={logo} alt="Logo" />
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
                <LinearProgress />
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ textAlign: "center" }}>{message}</Typography>
            </Grid>
        </Grid>
    </>
}

export default LoadingScreen;
