import { Box, Button, Card, CircularProgress, Container, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import CreateModal from "../components/createModal";
import AdminTable from "../components/adminTable";
import { useTranslation } from 'react-i18next';
import Header from "../components/header";
import useTenantStore from "../store/themeStore";
import DeleteConfirmationModal from "../components/deleteModal";
import { Tenant } from "../models/tenant";
import { createTenant, deleteTenant, getTenants, updateTenant } from "../services/tenantService";
import sad from "../assets/sad.svg";
import { Link } from "react-router-dom";

const emptyTenant = {
    name: "",
    description: "",
    aiContextInformation: "",
    theme: {
        primaryColor: "rgb(0,77,159)",
        secondaryColor: "rgb(27,255,255)"
    },
    salesContactEMail: "",
    salesContactPerson: "",
    exampleQuestion1: "Wofür steht ti&m?",
    exampleQuestion2: "Was sind die Spezialgebiete von ti&m in der digitalen Transformation?",
    exampleQuestion3: "Welche Branchen bedient ti&m hauptsächlich?",

} as Tenant

export function AdminPage() {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [tenants, setTenants] = React.useState<Tenant[]>([]);
    const [createModalOpen, setCreateModalOpen] = React.useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const { setTenant } = useTenantStore();
    const [selectedTenant, setSelectedTenant] = React.useState<Tenant>(emptyTenant);

    useEffect(() => {
        loadTenants()
    }, []);

    function performDelete(id: number) {
        deleteTenant(id)
            .then(() => setTenants(tenants.filter(t => t.id !== id)))
            .catch(err => console.log(err)); // TODO Show error to user
        setCreateModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedTenant(emptyTenant);
    }

    function addTenant() {
        if (selectedTenant.id) {
            updateTenant(selectedTenant)
                .then(t => setTenants(tenants.map(to => to.id === t.id ? t : to)))
                .catch(err => console.error(err)); // TODO Show error to user
        } else {
            createTenant(selectedTenant)
                .then(t => setTenants([...tenants, t]))
                .catch(err => console.error(err)); // TODO: Show error to user
        }
        setCreateModalOpen(false);
    }

    function onCreate() {
        setSelectedTenant(emptyTenant);
        setCreateModalOpen(true);
    }

    function onEdit() {
        setCreateModalOpen(true);
    }

    function onSelect(tenant: Tenant) {
        setTenant(tenant);
        setSelectedTenant(tenant);
    }

    function loadTenants(){
        getTenants()
            .then(tenants => {
                setTenants(tenants);
                setLoading(false);
            })
    }

    return (
        <>
            <Header/>
            <Container sx={{ pt: 4 }}>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="h4">{t('adminTable.title')}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Link to="/document">
                            <Button fullWidth variant="outlined">{t('document.title')}</Button>
                        </Link>
                    </Grid>
                </Grid>
                <Card sx={{ padding: 2, marginTop: 2 }}>
                    <Tooltip title={t('tenant.create')}>
                        <IconButton onClick={onCreate}><AddIcon/></IconButton>
                    </Tooltip>
                    {loading
                        ? <Box sx={{ width: "100%", textAlign: "center" }}><CircularProgress/></Box>
                        : tenants.length === 0
                            ? <Grid container sx={{ textAlign: "center" }}>
                                <Grid item xs={12}>
                                    <Typography variant="h4">{t('adminTable.empty')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <img src={sad} alt="No Content" width="40%"/>
                                </Grid>
                                <Grid item xs={4}/>
                                <Grid item xs={4}>
                                    <Button variant="contained" fullWidth
                                            onClick={() => onCreate()}>{t('tenant.create')}</Button>
                                </Grid>
                                <Grid item xs={4}/>
                            </Grid>
                            : <AdminTable entries={tenants} selectedTenant={selectedTenant} onSelect={onSelect}
                                          openDelete={() => setDeleteModalOpen(true)} openEdit={onEdit}
                                          openTenant={() => setCreateModalOpen(true)}/>
                    }
                </Card>
            </Container>
            <CreateModal tenant={selectedTenant} setTenant={setSelectedTenant}
                         open={createModalOpen}
                         onClose={() => setCreateModalOpen(false)}
                         addCompany={addTenant}
                         deleteCompany={() => setDeleteModalOpen(true)}
            />
            <DeleteConfirmationModal
                open={deleteModalOpen}
                onDelete={() => performDelete(selectedTenant.id)}
                onCancel={() => setDeleteModalOpen(false)}
                title={t('tenant.actions.delete')}
                cancelText={t('tenant.actions.cancel')}
                deleteText={t('tenant.actions.delete')}
                disclaimer={t('tenant.deleteDisclaimer')}
                disclaimer2={t('spidermanQuote')}
            />
        </>
    )
}

export default AdminPage;
