export interface Tenant {
    id: number;
    publicId: string;
    name: string;
    description: string;
    aiContextInformation: string;
    theme: Theme;
    salesContactPerson: string;
    salesContactEMail: string;
    exampleQuestion1: string;
    exampleQuestion2: string;
    exampleQuestion3: string;
    default?: boolean
}

export interface Theme {
    primaryColor: string;
    secondaryColor: string;
    logo: string | undefined;
}

export const defaultTenant: Tenant = {
    id: 0,
    publicId: "",
    name: "ti&m default tenant",
    description: "Fallback tenant if no parameter is set",
    aiContextInformation: "",
    theme: {
        primaryColor: "rgb(0,77,159)",
        secondaryColor: "rgb(27,255,255)",
        logo: undefined
    },
    salesContactPerson: "Ralf Steinhauer",
    salesContactEMail: "ralf.steinhauer@ti8m.ch",
    exampleQuestion1: "Wofür steht ti&m?",
    exampleQuestion2: "Was sind die Spezialgebiete von ti&m in der digitalen Transformation?",
    exampleQuestion3: "Welche Branchen bedient ti&m hauptsächlich?",
    default: true
}
