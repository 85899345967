import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export interface DeleteConfirmationModalProps {
    title: string;
    disclaimer: string;
    disclaimer2?: string;
    cancelText: string;
    deleteText: string;
    open: boolean;
    onCancel: () => void;
    onDelete: () => void;
}


export default function DeleteConfirmationModal({ title, disclaimer, disclaimer2, cancelText, deleteText, open, onCancel, onDelete }: DeleteConfirmationModalProps) {
    return <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <Typography>{disclaimer}</Typography>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>{disclaimer2}</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel} variant="outlined" color="info">{cancelText}</Button>
            <Button onClick={onDelete} variant="contained" color="error">{deleteText}</Button>
        </DialogActions>
    </Dialog>
}
