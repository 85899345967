import { Alert, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { Delete, Edit, Link, MoreVert } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Tenant } from "../models/tenant";

export interface AdminTableProps {
    entries: Tenant[],
    onSelect: (tenant: Tenant) => void
    openEdit: () => void
    openDelete: () => void
    openTenant: () => void
    selectedTenant: Tenant | undefined
}

export function AdminTable({ entries, onSelect, openEdit, openTenant, openDelete, selectedTenant }: AdminTableProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    function onClick(tenant: Tenant) {
        // only select tenant if the menu isn't open
        if (!open) {
            onSelect(tenant);
        }
    }

    function copyLink() {
        navigator.clipboard.writeText(`${window.location.origin}/?t=${selectedTenant?.publicId}`)
        writeToSnackbar(t('tenant.actions.copySuccess'))
        setAnchorEl(null);
    }

    function writeToSnackbar(message: string) {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('tenant.companyName')}</TableCell>
                            <TableCell>{t('tenant.description')}</TableCell>
                            <TableCell>{t('tenant.logo')}</TableCell>
                            <TableCell>{t('tenant.colors')}</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entries.map((tenant: Tenant) => (
                            <TableRow
                                onClick={() => onClick(tenant)}
                                onDoubleClick={() => openTenant()}
                                key={tenant.id}
                                selected={tenant.id === selectedTenant?.id}
                                hover={true}
                            >
                                <TableCell component="th" scope="row">
                                    {tenant.name}
                                </TableCell>
                                <TableCell align="left">{tenant.description}</TableCell>
                                <TableCell align="left"><img className="logo-width" src={tenant.theme.logo} alt="Logo" /></TableCell>
                                <TableCell align="left">
                                    <div className="colorContainer">
                                        <div className="box" style={{ backgroundColor: tenant.theme.primaryColor }}></div>
                                        <div className="box" style={{ backgroundColor: tenant.theme.secondaryColor }}></div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                                        <MoreVert />
                                    </IconButton>
                                    <Menu open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                                        <MenuList>
                                            <MenuItem onClick={() => {
                                                setAnchorEl(null);
                                                openEdit()
                                            }}>
                                                <ListItemIcon>
                                                    <Edit />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {t('tenant.actions.edit')}
                                                </ListItemText>
                                            </MenuItem>
                                            <MenuItem onClick={() => copyLink()}>
                                                <ListItemIcon>
                                                    <Link />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {t('tenant.actions.copyLink')}
                                                </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem onClick={() => {
                                                setAnchorEl(null);
                                                openDelete();
                                            }}>
                                                <ListItemIcon>
                                                    <Delete />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {t('tenant.actions.delete')}
                                                </ListItemText>
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity="success">{snackbarMessage}</Alert>
            </Snackbar>
        </>
    )
}

export default AdminTable;
