import { Alert, Avatar, Box, CircularProgress, Grid, Theme, Typography, useTheme } from "@mui/material";
import { ChatMessage, MessageType } from "../pages/ChatPage";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import botLogo from '../assets/logo_no_tag.svg';
import { defaultTenant } from "../models/tenant";
import { Person } from "@mui/icons-material";
import Markdown from "react-markdown";

export interface ChatMessageBoxProps {
    message: ChatMessage,
    userLogo: string | undefined,
}

export default function ChatMessageBox({ message, userLogo }: ChatMessageBoxProps) {
    const theme: Theme = useTheme();
    const isUser: boolean = message.messageType === MessageType.USER;
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState('');

    const color: string = isUser ? theme.palette.primary.main : defaultTenant.theme.primaryColor;

    useEffect(() => {
        if (message.errorType != null) {
            const randomIndex = Math.floor(Math.random() * 3);
            setErrorMessage(t(`chat.errors.${message.errorType}.${randomIndex}`));
        } else {
            setErrorMessage(t('chat.errors.GENERAL'));
        }
    }, [message.errorType, t]);

    return <Grid container sx={{ mt: 2, borderRadius: 8 }}>
        <Grid container columns={36} sx={{ marginBottom: '-1rem' }}>
            <Grid item xs={6} md={2}>
                <Box sx={{ pr: 2 }}>
                    {isUser
                        ? userLogo ? <img src={userLogo} alt={message.messageType} style={{ width: "100%" }} /> : <Avatar><Person /></Avatar>
                        : <img src={botLogo} alt={message.messageType} style={{ width: "100%" }} />
                    }
                </Box>
            </Grid>
            <Grid item xs={30} md={34} sx={{ alignContent: 'center' }}>
                <Typography color={color} variant='subtitle1'>{t(`chat.role.${message.messageType}`)}</Typography>
            </Grid>
        </Grid>
        <Grid container columns={36}>
            <Grid item xs={6} md={2}>
                {/*Block for indentation*/}
            </Grid>
            <Grid item xs={30} md={34}>
                <Markdown>{message.content}</Markdown>
                {!message.completed && !message.error &&
                    <CircularProgress size={theme.typography.body1.fontSize} sx={{ ml: 1, mt: '1rem', verticalAlign: 'middle' }} />
                }
                {message.error &&
                    <Alert severity="error" component="span" sx={{ mt: 1 }}>
                        {errorMessage}
                    </Alert>
                }
            </Grid>
        </Grid>
    </Grid>
}
